import React from "react"

import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Toolbar from "@mui/material/Toolbar"

import { Link } from "gatsby-theme-material-ui"

import TopMenu from "./topMenu"
import SideMenu from "./sideMenu"

import background from "../images/blue_wallpaper.webp"
import masthead from "../images/masthead.webp"

export default function Header() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="relative"
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: "150px auto",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters id="back-to-top-anchor">
            <SideMenu />
            <Box sx={{ flexGrow: 1 }}>
              <Container
                maxWidth="lg"
                sx={{
                  backgroundImage: `url(${masthead})`,
                  backgroundSize: "auto 100px",
                  backgroundRepeat: "no-repeat",
                  height: "100px",
                }}
              ></Container>
            </Box>
            <Box sx={{ display: { xs: "none", lg: "inline" } }}>
              <Button
                sx={{ px: 1, mx: 1, border: 1, borderColor: "white" }}
                variant="contained"
              >
                <a
                  href="https://bridge.watch/country"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    fontSize: "18px",
                    fontWeight: 400,
                  }}
                >
                  U.S. Overview
                </a>
              </Button>
              <Button
                sx={{ px: 1, mx: 1, border: 1, borderColor: "white" }}
                variant="contained"
              >
                <a
                  href="https://bridge.watch/state"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    fontSize: "18px",
                    fontWeight: 400,
                  }}
                >
                  State Info
                </a>
              </Button>
              <Button
                sx={{ px: 1, mx: 1, border: 1, borderColor: "white" }}
                variant="contained"
              >
                <a
                  href="https://bridge.watch/condition"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    fontSize: "18px",
                    fontWeight: 400,
                  }}
                >
                  Condition Info
                </a>
              </Button>
              <TopMenu/>
              <Button
                sx={{ px: 1, mx: 1, border: 1, borderColor: "white" }}
                variant="contained"
              >
                <Link
                  to="/"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    fontSize: "18px",
                    fontWeight: 400,
                  }}
                >
                  Blog
                </Link>
              </Button>
              <Button
                sx={{ px: 1, mx: 1, border: 1, borderColor: "white" }}
                variant="contained"
              >
                <a
                  href="https://bridge.watch/about"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    fontSize: "18px",
                    fontWeight: 400,
                  }}
                >
                  About
                </a>
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}
