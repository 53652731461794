import React from "react"
import { useState } from "react"

import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import { makeStyles } from "@mui/styles"
import MenuIcon from "@mui/icons-material/Menu"

import { Link } from "gatsby-theme-material-ui"

const routes = [
  { key: "U.S. Overview", value: "https://bridge.watch/country" },
  { key: "State Info", value: "https://bridge.watch/state" },
  { key: "Condition Info", value: "/condition" },
]

const backgroundRoutes = [
  { key: "Bridge Types", value: "https://bridge.watch/bridge_types" },
  { key: "Bridge Materials", value: "https://bridge.watch/bridge_materials" },
]

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: "black",
    fontVariant: "small-caps",
  },
})

export default function SideMenu() {
  const [state, setState] = useState(false)
  const classes = useStyles()

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setState(open)
  }

  return (
    <Box sx={{ display: { xs: "inline", lg: "none" } }}>
      <IconButton
        size="medium"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={toggleDrawer(true)}
      >
        <Drawer open={state} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {routes.map(route => (
                <a
                  className={classes.link}
                  activeClassName="active"
                  href={route.value}
                >
                  <ListItem
                    button
                    key={route.key}
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary={route.key} />
                  </ListItem>
                </a>
              ))}
              <ListItem>
                <ListItemText
                  sx={{
                    fontVariant: "small-caps",
                  }}
                  primary="Background Information"
                />
              </ListItem>
              {backgroundRoutes.map(route => (
                <a
                  className={classes.link}
                  activeClassName="active"
                  href={route.value}
                >
                  <ListItem
                    button
                    key={route.key}
                    onClick={toggleDrawer(false)}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary={route.key} />
                  </ListItem>
                </a>
              ))}
              <Link
                className={classes.link}
                activeClassName="active"
                href="/"
              >
                <ListItem button key={"Blog"} onClick={toggleDrawer(false)}>
                  <ListItemText primary="Blog" />
                </ListItem>
              </Link>
              <a
                className={classes.link}
                activeClassName="active"
                href="https://bridge.watch/about"
              >
                <ListItem button key={"About"} onClick={toggleDrawer(false)}>
                  <ListItemText primary="About" />
                </ListItem>
              </a>
            </List>
          </Box>
        </Drawer>
        <MenuIcon />
      </IconButton>
    </Box>
  )
}
