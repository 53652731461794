import * as React from "react"
import { Link } from "gatsby"

import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import { ThemeProvider } from "@mui/material/styles"

import Header from "../components/header"
import Footer from "../components/footer"
import Theme from "../gatsby-theme-material-ui-top-layout/theme"

const Layout = ({ location, title, children, theme }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <>
      <ThemeProvider theme={Theme}>
        <Box sx={{ bgcolor: "background.flat" }}>
          <Header />
          <Container maxWidth="lg">
            <div
              className="global-wrapper"
              data-is-root-path={isRootPath}
              style={{ position: "relative", minHeight: "100vh" }}
            >
              <header className="global-header">{header}</header>
              <main>{children}</main>
              <footer>
                © {new Date().getFullYear()}, Built with
                {` `}
                <a href="https://www.gatsbyjs.com">Gatsby</a>
              </footer>
            </div>
          </Container>
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  )
}

export default Layout
