import React from "react"

import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"

import BookIcon from "@mui/icons-material/Book"
import EmailIcon from "@mui/icons-material/Email"
import GitHubIcon from "@mui/icons-material/GitHub"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import TwitterIcon from "@mui/icons-material/Twitter"

import KofiButton from "kofi-button"

import background from "../images/blue_wallpaper.webp"

export default function Footer() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 2,
        boxShadow: 3,
        bgcolor: "primary.main",
        color: "primary.contrastText",
        backgroundImage: `url(${background})`,
        backgroundSize: "150px auto",
      }}
    >
      <Container maxWidth="lg">
        <Grid container sx={{ padding: 2 }} spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" style={{ color: "white", textShadow: "#000 1px 0 10px" }}>
              Bridge.watch 
            </Typography>
            <Typography
              variant="body1"
              style={{ textShadow: "#000 1px 0 10px" }}
            >
              Bridge.watch provides interactive data visualization for
              open-access data records from the FHWA of bridges located in the
              United States.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" style={{ color: "white", textShadow: "#000 1px 0 10px" }}>
              License
            </Typography>
            <Typography>
              This work is licensed under a{" "}
              <Link
                style={{ textShadow: "#000 1px 0 10px" }}
                variant="body1"
                underline="hover"
                color="inherit"
                href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
              >
                Creative Commons-Attribution-Non Commercial-Share Alike 4.0
                License
              </Link>
              . Contact the author for more information.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ color: "white", textShadow: "#000 1px 0 10px" }}>
                  Contact
                </Typography>
                <Typography variant="body1" style={{ textShadow: "#000 1px 0 10px" }}>
                  Maryanne Wachter
                </Typography>
              </Grid>
              <Grid sx={{pt: 1}} container spacing={2}>
                <Grid item>
                  <Link href="mailto:m.wachter@utsv.net" target="_blank">
                    <EmailIcon style={{dropShadow: "#000 1px 0 10px"}}
                               sx={{color: "common.white"}}/>
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://www.linkedin.com/in/m-wachter/" target="_blank">
                    <LinkedInIcon style={{dropShadow: "#000 1px 0 10px"}}
                               sx={{color: "common.white"}}/>
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://twitter.com/eng_mclare" target="_blank">
                    <TwitterIcon style={{dropShadow: "#000 1px 0 10px"}}
                                  sx={{color: "common.white"}}/>
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://github.com/m-clare" target="_blank">
                    <GitHubIcon style={{dropShadow: "#000 1px 0 10px"}}
                                 sx={{color: "common.white"}}/>
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://mclare.blog" target="_blank">
                    <BookIcon style={{dropShadow: "#000 1px 0 10px"}}
                                 sx={{color: "common.white"}}/>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ borderColor: "common.white"}}/>
          </Grid>
          <Grid item xs={12} md={4} sx={{textAlign: {xs: "center", md: "left"}}} style={{paddingTop: "8px"}}>
            <KofiButton color="#c44436" kofiID="mclare" title="Support this project" />
          </Grid>
          <Grid item xs={12} md={4} sx={{textAlign: {xs: "center"}}} style={{paddingTop: "8px"}}>
            <p
              style={{fontSize: "0.8rem", textAlign: "center", textShadow: "#000 1px 0 10px"}}>
              All rights reserved © UTSV, 2021
            </p>
          </Grid>
          <Grid item xs={12} md={4} sx={{textAlign: {xs: "center", md: "right"}}} style={{paddingTop: "8px"}}>
            <a href='http://www.recurse.com' title='Made with love at the Recurse Center'><img src='https://cloud.githubusercontent.com/assets/2883345/11325206/336ea5f4-9150-11e5-9e90-d86ad31993d8.png' height='20px' alt="Made with love at the Recurse Center"/></a>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
