import React from "react"

import { useState } from "react"

import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"

export default function TopMenu() {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        sx={{ px: 1, mx: 1, border: 1, borderColor: "white" }}
        style={{
          color: "#fff",
          textDecoration: "none",
          fontSize: "18px",
          fontWeight: 400,
        }}
        variant="contained"
        id="background-info-button"
        aria-controls="background-info-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Background
      </Button>
      <Menu
        id="background-info-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "background-info-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <a
            href="https://bridge.watch/bridge_types"
            style={{color: "#1c5d99", textDecoration: "none", fontVariant: "small-caps"}}
          >
            Bridge Types
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a
            href="https://bridge.watch/bridge_materials"
            style={{color: "#1c5d99", textDecoration: "none", fontVariant: "small-caps"}}
          >
            Bridge Materials
          </a>
        </MenuItem>
      </Menu>
    </>
  )
}
